.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding-bottom: 40px;
    padding-left: 25px;
    padding-right: 25px;
  }
  
  .grid-item {
    text-align: left;
  }
  
  .image-box {
    width: 100%;
    aspect-ratio: 1/1; /* Adjust ratio as needed */
    background-color: #ccc; /* Placeholder for image */
  }
  
  .image-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  p {
    margin-top: 10px;
    font-size: .75rem;
    color: #333;
    align-content: left;
  }


  .introduction {
    margin-top: 10px;
    margin-bottom: 80px;
    max-width: 700px;
    margin: auto;
  }
  

  @media only screen and (max-width: 768px) {
    .introduction {
      max-width: 300px;
    }

    p {
      font-size: .65rem;
    }
  }