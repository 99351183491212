.App {
  text-align: center;
  overflow-x: hidden;
}

.article {
  margin-top: 30px;
  
}

.copyright {
  font-size: 1rem;
}

nav {
  float: right;
  position: absolute;
  right: -10px;
  top: 10px;
}

nav a {
  margin-right: 20px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

a:visited { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:focus { text-decoration: none; }
a:hover, a:active { text-decoration: none; }

.content {
  max-width: 40.5rem;
  margin: auto;
  text-align: left;
}

.article div {
  margin-left: 20px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .article div {
    margin-left: 0px;
  }

  .content {
    font-size: 12px;
    max-width: 300px;
  }

  nav {
    font-size: 14px;
  }

  .copyright {
    font-size: .85rem;
  }

  
}

.section-header {
  margin-top: 25px;
  font-weight: bold;
  margin-bottom: 12px;
}

.section {
 color: black;
}


.recount-container {
  max-width: 500px;
  margin: auto;
  margin-top: 40px;
}

.divine {
  margin-bottom: 20px;
}

.article-header {
  margin-top: 50px;
  font-weight: bold;
}
